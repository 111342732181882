import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import fouille from '../assets/fouille.jpg';
import partition from '../assets/partition.jpg';
import USBfile from '../assets/USB.zip'
import map from '../assets/map.jpg'

const Fouille = () => {
  const { state } = useLocation();
  const [showPartition, setShowPartition] = useState(false);
  const [showMap, setShowMap] = useState(false);

  // const handleAudio = () => {
  //   console.log('test')
  //   setShowAudio(() => !showAudio);
  // }

  // DoLaRe = 8
  // ReMi = 6
  // DoRe = 3
  // FaRe = 11
  const correctSequence = [8, 6, 3, 11];
  const [currentStep, setCurrentStep] = useState(0);
  const [firstClick, setFirstClick] = useState(true);
  const [hasMadeErrors, setHasMadeErrors] = useState(false);


  const handlePartition = () => {
    setShowPartition(() => !showPartition);
  }

  const handleMap = () => {
    setShowMap(() => !showMap);
  }

  const checkSequence = (e) => {
    if (!e || !e.currentTarget) {
      console.error("L'événement ou sa cible est null ou undefined.");
      return;
    }

    // const currentEvent = e; // Stocke l'événement dans une variable
    const buttonId = parseInt(e.currentTarget.id);

    console.log('element Id', buttonId);
    console.log('currentStep', correctSequence[currentStep]);

    // console.log(buttonId === correctSequence[currentStep]);

    processEvent(buttonId);
  };

  const processEvent = (buttonId) => {
    const buttonList = document.querySelectorAll('[id="1b"], [id="2b"], [id="3b"], [id="4b"], [id="5b"], [id="6b"], [id="7b"], [id="8b"], [id="9b"], [id="10b"], [id="11b"]');
    const buttonArray = [...buttonList];
    setCurrentStep((prevStep) => {
      // Si c'est le premier clic, ne réinitialise pas, même si la réponse est incorrecte
      // if (firstClick) {
      //   document.getElementById(buttonId).classList.add('activeButton')
      //   setFirstClick(false);
      //   return prevStep;
      // }

      if(hasMadeErrors) {
        setFirstClick(true);
        setHasMadeErrors(false)

        buttonArray.map(button => button.classList.remove('activeButton'))
        // document.getElementById('8').classList.remove('activeButton')
        // document.getElementById('3').classList.remove('activeButton')
        // document.getElementById('11').classList.remove('activeButton')
        // document.getElementById('6').classList.remove('activeButton')
        return 0;
      }

      if (buttonId === correctSequence[prevStep]) {
        // Bouton correct, passe à l'étape suivante
        console.log('TEST 1')
        // document.getElementById(buttonId).classList.add('activeButton')
        // document.getElementById(`${buttonId}`).classList.add('inactiveButton')
        document.getElementById(`${buttonId}b`).classList.add('activeButton')
        if (prevStep === correctSequence.length - 1) {
          // La séquence est correcte jusqu'à la fin
          console.log('TEST 2')
          // alert('Séquence correcte ! Vous pouvez continuer.');

          document.getElementById('result').classList.add('active')

          // On reset tout après qqes secondes
          setTimeout(() => {
            document.getElementById('result').classList.remove('active')

            buttonArray.map(button => button.classList.remove('activeButton'))
          }, 10000);
          setFirstClick(true);
          return 0; // Réinitialise l'étape après avoir réussi
        } else {
          setFirstClick(false)
          return prevStep + 1;
        }
      } else {
        console.log('TEST3')
        if (firstClick) {
          console.log('TEST4')

          // C'est la première tentative, ne réinitialise pas la séquence
          // alert('Première tentative incorrecte. Continuez.');
          // document.getElementById(buttonId).classList.add('activeButton')
          document.getElementById(`${buttonId}b`).classList.add('activeButton')

          setHasMadeErrors(true);
          setFirstClick(false);
          return prevStep;
        } else {
          console.log('TEST5')

          // Bouton incorrect, réinitialise la séquence
          // alert('Séquence incorrecte. Réinitialisation...');
          setFirstClick(true);


          buttonArray.map(button => button.classList.remove('activeButton'))
        
          return 0;
        }
      }
    });
  };

  const confirmDownload = (e) => {
    const confirmed = window.confirm('Vous allez télécharger le contenu de la clé USB. Voulez-vous continuer ?');
    if (!confirmed) {
      e.preventDefault(); // Empêche le comportement par défaut (le téléchargement)
    }
  }

  return (
    state?.isLoggedInLvl1 ?

      <span>

        {/* Map, shown full screen with elements on top */}
        {showMap ?

          <div className="absolute-container">
            <img className="image-map" src={map} alt="Logo" width={'100%'} />
            <div id="1b" className="map-button map-1"></div>

            <div id="1" className="map-button map-1" onClick={checkSequence}></div>
            <div id="2b" className="map-button map-2"></div>

            <div id="2" className="map-button map-2" onClick={checkSequence}></div>
            <div id="3b" className="map-button map-3"></div>

            <div id="3" className="map-button map-3" onClick={checkSequence}></div>
            <div id="4b" className="map-button map-4"></div>

            <div id="4" className="map-button map-4" onClick={checkSequence}></div>
            <div id="5b" className="map-button map-5"></div>

            <div id="5" className="map-button map-5" onClick={checkSequence}></div>
            <div id="6b" className="map-button map-6"></div>

            <div id="6" className="map-button map-6" onClick={checkSequence}></div>
            <div id="7b" className="map-button map-7"></div>
            <div id="7" className="map-button map-7" onClick={checkSequence}></div>

            <div id="8b" className="map-button map-8"></div>
            <div id="8" className="map-button map-8" onClick={checkSequence}></div>

            <div id="9b" className="map-button map-9"></div>

            <div id="9" className="map-button map-9" onClick={checkSequence}></div>
            <div id="10b" className="map-button map-10"></div>

            <div id="10" className="map-button map-10" onClick={checkSequence}></div>
            
            <div id="11b" className="map-button map-11"></div>
            <div id="11" className="map-button map-11" onClick={checkSequence}></div>

            <div id="12b" className="map-button map-12"></div>
            <div id="12" className="map-button map-12" onClick={checkSequence}></div>

            <div id="13b" className="map-button map-13"></div>
            <div id="13" className="map-button map-13" onClick={checkSequence}></div>

            <div id="14b" className="map-button map-14"></div>
            <div id="14" className="map-button map-14" onClick={checkSequence}></div>

            <div id="15b" className="map-button map-15"></div>
            <div id="15" className="map-button map-15" onClick={checkSequence}></div>

            <div id="16b" className="map-button map-16"></div>
            <div id="16" className="map-button map-16" onClick={checkSequence}></div>

            <div className="streetName"><span id="result" className="streetNameSpan">gaia</span></div>
            <div className="close-map" onClick={() => setShowMap(false)}>X</div>
          </div>

          : null
        }

        <div className="fouille-relative-container">


          <a href={USBfile} download="Cle-USB-Mysterieuse" className="USB" onClick={confirmDownload}>
            {/* <div className="test">My Example Doc</div> */}
          </a>

          {/* <a href={map} download="Carte" className="map" onClick={confirmDownloadMap}></a> */}



          <img className="image-fouille" src={fouille} alt="Logo" />

          <div className="map" onClick={handleMap}>

          </div>



          <div className="partition" onClick={handlePartition}>
            {showPartition ?

              <div>
                <img className="image-partition" src={partition} alt="Logo" width={'100%'} />
                <div className="close" onClick={() => setShowPartition(false)}>X</div>
              </div>

              : null}
          </div>
        </div>

      </span>

      :
      <div>connecte toi</div>


  );
};

export default Fouille;
