import React, { useState } from "react";
import { Route, Routes, Link } from "react-router-dom";

import Code from "./pages/Code";
import Fouille from "./pages/Fouille";

// fonts
import './fonts/Courgette-Regular.ttf';

const App = () => {
  const [isLoggedInLvl1, setIsLoggedInLvl1] = useState(false);
  const isMobile = window.innerWidth <= 1024;

  return (
    // <div className={isLoggedInLvl1? "page-container" : "page-container-home"}>

    <div className="page-container">
      {!isMobile &&
        <Routes>
          <Route path="/" element={<Code isLoggedInLvl1={isLoggedInLvl1} setIsLoggedInLvl1={setIsLoggedInLvl1} />} />
          <Route path="*" element={<NoMatch />} />
          <Route path="/fouille" element={<Fouille />} />
        </Routes>
      }
      {isMobile &&
        <div className="mobile-container">
          <span>Ce site est conçu pour être utilisé sur un ordinateur. Merci de vous connecter via un bureau pour profiter pleinement de toutes les fonctionnalités.</span>
        </div>
        
      }
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}


export default App;