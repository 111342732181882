import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import background from '../assets/backgroundDana.png';
import symbol from '../assets/symbol.png';


import { constantCode } from '../config/constants'


const Code = ({ isLoggedInLvl1, setIsLoggedInLvl1 }) => {
  const [isDoorPlateActive, setDoorPlateActive] = useState(false);
  const [clignotements, setClignotements] = useState(0);

  const [numberCount, setNumberCount] = useState(0);
  const [number1, setNumber1] = useState("_");
  const [number2, setNumber2] = useState("_");
  const [number3, setNumber3] = useState("_");
  const [number4, setNumber4] = useState("_");
  const [number5, setNumber5] = useState("_");

  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const code = `${number1}${number2}${number3}${number4}${number5}`;


    if (code === constantCode) {
      setIsLoggedInLvl1(true);
      navigate("/fouille", { state: { isLoggedInLvl1: true } });
    } else {
      setErrorMessage("Code Incorrect")
    }
  };

  const handleClear = (event) => {
    event.preventDefault();

    setNumber1("_");
    setNumber2("_");
    setNumber3("_");
    setNumber4("_");
    setNumber5("_");
    setNumberCount(0);
    setErrorMessage('')
    console.log(event.target.innerText.trim())
  }

  const handleInputClick = (event) => {
    event.preventDefault();

    console.log(event.target.innerText)


    if (numberCount === 0) {
      setNumber1(event.target.innerText.trim())
    }

    if (numberCount === 1) {
      setNumber2(event.target.innerText.trim())
    }

    if (numberCount === 2) {
      setNumber3(event.target.innerText.trim())
    }

    if (numberCount === 3 && number4 === "_") {
      setNumber4(event.target.innerText.trim())
    }

    if (numberCount === 4 && number5 === "_") {
      setNumber5(event.target.innerText.trim())
    }

    if (numberCount < 4) {
      setNumberCount(numberCount + 1);
    }
  }


  useEffect(() => {
    const maxClignotements = 3; // Nombre maximum de clignotements

    // Fonction pour ajouter et retirer la classe toutes les 30 secondes
    const toggleDoorPlateClass = () => {
      setDoorPlateActive(true);

      // Retire la classe après 1 seconde
      setTimeout(() => {
        setDoorPlateActive(false);

        setClignotements((prevClignotements) => prevClignotements + 1);

        // Réinitialise le compteur après le nombre maximum de clignotements
        if (clignotements === maxClignotements) {
          // setClignotements(0);
          // clearInterval(intervalId);
          setClignotements((prevClignotements) => {
            if (prevClignotements === maxClignotements) {
              clearInterval(intervalId);
              return 0;
            }
            return prevClignotements + 1;
          });
        }
        setTimeout(function () {
          setDoorPlateActive(true);

          setTimeout(function () {
            setDoorPlateActive(false);
          }, 2000);
        }, 2000);
      }, 2000);
    };

    // Démarre l'intervalle toutes les 30 secondes
    const intervalId = setInterval(toggleDoorPlateClass, 10000);

    // Nettoie l'intervalle lorsqu'on quitte le composant
    return () => clearInterval(intervalId);
  }, [clignotements]); // Le tableau vide [] assure que cela ne s'exécutera qu'une seule fois à l'initialisation



  return (
    <div className="container">
      <img className="image-background" src={background} alt="Logo" width={'100%'} />
      <div className="welcomeMessageBackground">
        <div className="welcomeMessageText">
          Vous arrivez chez votre oncle et vous sonnez à la porte, mais personne ne répond. La porte est fermée et semble vérouillée par un code.<br /> <br />
          Peut être devriez vous chercher des indices...
        </div>
        <div className="symbols-area">
          <span className="symbol-group group1">
            <span><img className="symbol symbol1" src={symbol} alt="Logo" width={'100%'} /></span>
            <span><img className="symbol symbol2" src={symbol} alt="Logo" width={'100%'} /></span>
          </span>
          <span className="symbol-group group2">
            <span><img className="symbol symbol3" src={symbol} alt="Logo" width={'100%'} /></span>
          </span>
          <span className="symbol-group group3">
            <span><img className="symbol symbol4" src={symbol} alt="Logo" width={'100%'} /></span>
            <span><img className="symbol symbol5" src={symbol} alt="Logo" width={'100%'} /></span>
          </span>
          <span className="symbol-group group4">
            <span><img className="symbol symbol6" src={symbol} alt="Logo" width={'100%'} /></span>
            <span><img className="symbol symbol7" src={symbol} alt="Logo" width={'100%'} /></span>
          </span>
          <span className="symbol-group group5">
            <span><img className="symbol symbol8" src={symbol} alt="Logo" width={'100%'} /></span>
            <span><img className="symbol symbol9" src={symbol} alt="Logo" width={'100%'} /></span>
          </span>
        </div>
        {/* <div className="digits-squares">
          <span className="square"></span>
          <span className="square"></span>
          <span className="square"></span>
          <span className="square"></span>
          <span className="square"></span>
        </div>
        <div className="symbols-area">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div> */}
      </div>

      <div className={`doorPlate ${isDoorPlateActive ? 'active' : ''}`}></div>
      <div className="doorPlateImage"></div>

      <form>
        <div className="numPad">

          <div className="numPad-body">
            <div className="numPad-row">
              <div className="numPad-cell" onClick={handleInputClick}><span>1</span></div>
              <div className="numPad-cell" onClick={handleInputClick}><span>2</span></div>
              <div className="numPad-cell" onClick={handleInputClick}><span>3</span></div>
            </div>
            <div className="numPad-row">
              <div className="numPad-cell" onClick={handleInputClick}><span>4</span></div>
              <div className="numPad-cell" onClick={handleInputClick}><span>5</span></div>
              <div className="numPad-cell" onClick={handleInputClick}><span>6</span></div>
            </div>
            <div className="numPad-row">
              <div className="numPad-cell" onClick={handleInputClick}><span>7</span></div>
              <div className="numPad-cell" onClick={handleInputClick}><span>8</span></div>
              <div className="numPad-cell" onClick={handleInputClick}><span>9</span></div>
            </div>
            <div className="numPad-row">
              <div className="numPad-cell" onClick={handleClear}><span>X</span></div>
              <div className="numPad-cell" onClick={handleInputClick}><span>0</span></div>
              <div className="numPad-cell" onClick={handleSubmit}><span>V</span></div>
            </div>
          </div>
          <div className="numPad-header">
            <div className="square">{number1}</div>
            <div className="square">{number2}</div>
            <div className="square">{number3}</div>
            <div className="square">{number4}</div>
            <div className="square">{number5}</div>
          </div>
        </div>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </form>

    </div>
  );
};

export default Code;
